import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
import { getToken } from '@/utils/auth';
const errMsg = {
  401: '登录过期，请重新登录'
};
axios.interceptors.request.use(config => {
  config.baseURL = '/api';
  config.headers = {
    Authorization: `${getToken()}`
  };
  return config;
}, error => {
  // do something
  return Promise.reject(error);
});
// add response interceptors
axios.interceptors.response.use(async response => {
  const res = response.data;
  if (res.code !== 200) {
    _Message.error({
      content: errMsg[res.code] || res.data || '服务繁忙',
      duration: 5 * 1000
    });
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    if ([401].includes(res.code)) {
      localStorage.removeItem('userStatus');
      setTimeout(() => {
        window.location.href = '/login';
      }, 1500);
    }
    return Promise.reject();
  }
  return res;
}, error => {
  const status = error.response && error.response.status && error.response.status || '';
  switch (status) {
    case 401:
      error.msg = '登录超时';
      setTimeout(() => {
        localStorage.removeItem('userStatus');
        window.location.href = '/login';
      }, 1500);
      break;
    case 404:
      error.msg = '服务繁忙';
      break;
    case 500:
      error.msg = '服务繁忙';
      break;
    default:
    //
  }
  _Message.error({
    content: error.msg,
    duration: 5 * 1000
  });
  return Promise.reject(error);
});